import React, { Component } from "react";
import AuthLoginComp from "../component/AuthLogin/AuthLoginComp";
import { isMobile } from "react-device-detect";

class Fixes extends Component {
  render() {
    if (isMobile) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#A8A8A8",
            marginTop: "30%",
            gap: "20px"
          }}
        >
          <img src="assets/images/fixme 1.png" alt="" width="200px" />
          Please open it in the browser to use FixMe
          <img
            src="assets/images/TSAS-logo.png"
            alt="Logo"
            className="menu-logo fix-logo"
          />
        </div>
      );
    }
    return (
      <div>
        <AuthLoginComp />
      </div>
    );
  }
}

export default Fixes;
