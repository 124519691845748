import React, {Component} from 'react'
import {Link, Redirect } from 'react-router-dom'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import './CustomToolbar.css'
import swal from 'sweetalert2';
import { axiosInstance } from '../../Services/PostData';
import { SocketConnection } from '../../Services/SocketConnection';
import Cookies from 'universal-cookie';
import io from "socket.io-client";

const cookies = new Cookies();
//let socket = io.connect(SocketConnection)

const CustomToolbar = props => {

    let mainHeadClass = 'hide__container';
    let detailsHeadClass = 'hide__container';
    let chatHeadClass = 'hide__container';

    let routeRedirect = false

    if(props.propsName == 'fixes' || props.propsName == 'Profile') {
        mainHeadClass = '';
    }
    else if( props.propsName == 'fixesDetails' || 
             props.propsName == 'authNumber' || 
             props.propsName == 'aboutDetails' ||
             props.propsName == 'notificationDetails' || 
             props.propsName == 'editProfile'  
             ) 
    {
        detailsHeadClass = '';
    }
    else if(props.propsName == 'chatDetails') {
        chatHeadClass = '';
    }

    function clickFunction() {
        swal.fire({
            title: "Are you sure?",
            text: "your chat will end and your ticket will assign to closed",
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            cancelButtonText: "No",
            confirmButtonText: 'Yes',
            closeOnConfirm: false,
            closeOnCancel: false
          }).then((result) => {
            if (result.value) {
                routeRedirect = true
                if(routeRedirect) {
                    // return <Redirect to='/Dasahboard' />
                    // window.location.href("http://localhost:3000")
                    // window.location.replace("http://localhost:3000/Dashboard")
                    cancelFixes();
                }
            }
          })
    }

    function cancelFixes() {
        let queryValue = cookies.get('sessionCusChatRoomId');

        const body = { chat_room_id: queryValue };
        axiosInstance.post(`closeFixes`, body)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
        {
            let to_connect = "tech-user"+ res.data.tech_user;
            let message = 'customer closed your ticket';
            props.socket.emit('closeChatRoonConnection', to_connect, message)

            cookies.remove('fixesRequestChatId');
            cookies.remove('sessionCusChatRoomId');
            window.location.reload(false)
        }
        })
        .catch(err => console.log('Login: ', err));
  
      }

      let fixesRequestChatId_session = cookies.get('fixesRequestChatId');
      let sessionCusChatRoomId_session = cookies.get('sessionCusChatRoomId');
    //   console.log(fixesRequestChatId_session)
    //   console.log(sessionCusChatRoomId_session)

      function requestCancel() {
        swal.fire({
            title: "Are you sure?",
            text: "You want to close request sent to fixer",
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            cancelButtonText: "No",
            confirmButtonText: 'Yes',
          }).then((result) => {
            if (result.value) {
                routeRedirect = true
                if(routeRedirect) {
                    cancelCustomerRequest();
                }
            }
          })
      }

      function cancelCustomerRequest() {

        let queryValue = cookies.get('fixesRequestChatId');
        const body = { fix_req_id: queryValue };
        axiosInstance.post(`cancelCustomerRequest`, body)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
        {
            let to_connect = "tech-user"+ res.data.tech_user;
            let message = 'customer closed your Request';
            props.socket.emit('closeChatRoonConnection', to_connect, message)

            cookies.remove('fixesRequestChatId');
            cookies.remove('sessionCusChatRoomId');
            window.location.reload(false)
        }
        })
        .catch(err => console.log('Login: ', err));
      }

    return(
        <header className="toolbar">
            
            {/* main head sectio */}
            <nav className="toolbar__navigation" id={mainHeadClass}>
                <div className="toggle___button">
                    <DrawerToggleButton click={props.drawerClickHandler} />
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
            </nav>
            
            {/* Details head section */}
            <nav className="toolbar__navigation" id={detailsHeadClass}>
                <div className="toggle___button">
                    <Link className="colorWhite" to={props.backLink}><i class="material-icons backBtnClas">west</i></Link>
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
                
            </nav>

            {/* Chat head section */}
            <nav className="toolbar__navigation" id={chatHeadClass}>
                <div className="toggle___button">
                    {/* <Link className="colorWhite" to={props.backLink} onClick={clickFunction}>
                            <i class="material-icons backBtnClas">west</i>
                        </Link> */}

                {sessionCusChatRoomId_session != undefined ?
                    <p className="colorWhite" onClick={clickFunction}><i class="material-icons backBtnClas">west</i></p>
                    :
                    <p className="colorWhite" onClick={requestCancel}><i class="material-icons backBtnClas">west</i></p>
                }
                    
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
                <Link to="/Dashboard" className="colorWhite"><i class="material-icons backBtnClas">remove</i></Link>
            </nav>
            
        </header>
    )
}
 
export default CustomToolbar