import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./AuthLoginComp.css";
import ClientJS from "clientjs";
import { axiosInstance } from "../../Services/PostData";
import Cookies from "universal-cookie";
import { QRCode } from "react-qr-svg";
import { SocketConnection } from "../../Services/SocketConnection";
import io from "socket.io-client";

import ChatComponent from "../Chat/ChatDetailsComp";

const cookies = new Cookies();

class AuthLoginComp extends Component {
  constructor() {
    super();

    this.state = {
      getBroserData: "",
      email: "",
      mobile_number: "",
      password: "",
      confirm_password: "",
      gender: "",
      country: "",
      nameError: "",
      ip_address: "",
      client_ip_address: "",
      teams: [],
      prefix: "",
      mobile_number_count: "",
      phone_number: "",
      authenticationNumber: "",
      showError: "",
      vnc_number: "",
      socket: null
    };
    this.details = this.details.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitAuthentication = this.submitAuthentication.bind(this);
    this.getCountryPrefix = this.getCountryPrefix.bind(this);
    this.verifyUserDetails = this.verifyUserDetails.bind(this);
    this.userValidation = this.userValidation.bind(this);
    this.callsocket = this.callsocket.bind(this);
    this.launchConnectionFunction = this.launchConnectionFunction.bind(this);
    this.updateDownload = this.updateDownload.bind(this);
  }

  componentDidMount() {
    let response = cookies.get("response");
    console.log({ response });
    if (response !== undefined && false) {
      if (response.chat_available_status == "1") {
        //get vnc number and store
        let vnc_number_length = response.details.length;
        if (vnc_number_length > 0) {
          let vnc_number = response.details[0].vnc_chat_number;
          this.setState({ vnc_number: vnc_number });
        }

        //getting mobile number
        let customer_mobile_number = response.customer_mobile;
        let tech_mobile_number = response.tech_mobile;
        this.setState({ customer_mobile_number, tech_mobile_number });

        cookies.set("fixesRequestChatId", response.fix_request_id);
        cookies.set("userName", response.tech_name);
        this.setState({ showError: "" });
        this.setState({ showClientDetails: true });
        this.setState({ st_customer_user_id: response.customer_user_id });
        this.launchFunctionJoinRoom(response.customer_user_id);
      } else {
        this.setState({
          showError:
            "Ticket is not created, Please create ticket in mobile application to proceed"
        });
      }
    }

    let socket = io(SocketConnection);

    this.setState({ socket });
    console.log(SocketConnection + socket);

    // cookies.set("fixesRequestChatId", 2123);
    /*if(cookies.get('userId') == undefined) {
            this.setState({redirect: true});
        }
        else {

            let fixesRequestChatId = cookies.get('fixesRequestChatId');
            if(fixesRequestChatId == undefined) {
                this.setState({dashboardRedirect: true})
            }

            this.getCountryDetails();
        }*/

    /*let fixesRequestChatId = cookies.get('fixesRequestChatId');
        if(fixesRequestChatId == undefined) {
            this.setState({dashboardRedirect: true})
        }*/

    this.getCountryDetails();
    this.details();

    //***get Launch Collection details ***
    socket.on(
      "getScannerDeviceDetails",
      (name, active_status, download_status, install_status) => {
        this.launchConnectionFunction(
          active_status,
          download_status,
          install_status
        );
      }
    );
  }

  callsocket() {
    let user_var = "scanConnectionUserCustomerTSAS_";
    let { socket } = this.state;
    // socket.emit("join room", user_var);
  }

  getCountryPrefix(e) {
    this.state.prefix = e.target.value;
    this.state.mobile_number_count =
      e.target.selectedOptions[0].getAttribute("data-length");
  }

  getCountryDetails() {
    axiosInstance
      .get(`getCountryDetails`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          //setting mobile number lengtha and prefix
          this.state.mobile_number_count =
            res.data.country_details[0].mobile_length;
          this.state.prefix = res.data.country_details[0].prefix;

          let country_details = res.data.country_details;
          let teamsFromApi = country_details.map((team) => {
            return team;
          });
          this.setState({
            teams: teamsFromApi
          });
        }
      })
      .catch((err) => console.log("CountryDetails: ", err));
  }

  details() {
    const client = new ClientJS();
    const windowClient = new window.ClientJS();

    let getBrowserData = windowClient.getBrowser();
    let fingerPrint = windowClient.getFingerprint();
    let browserversion = windowClient.getBrowserVersion();
    let os = windowClient.getOS();
    let getOSVersion = windowClient.getOSVersion();

    this.setState({
      getBrowserData,
      fingerPrint,
      browserversion,
      os,
      getOSVersion
    });
  }

  userValidation() {
    let phoneError = "";
    //Mobile Number
    if (!this.state.phone_number) {
      phoneError = "Enter Mobile Number";
    }

    if (phoneError) {
      this.setState({ phoneError });
      return false;
    }

    return true;
  }

  verifyUserDetails() {
    const body = {
      prefix: this.state.prefix,
      user_id: this.state.phone_number
    };

    const isValid = this.userValidation();
    if (isValid) {
      this.setState({ phoneError: "" });

      axiosInstance
        .post(`verifyAuthUserDetails`, body)
        .then((res) => {
          if (res.data.status === "success" && res.data.statusCode === "200") {
            this.setState({ showOtpField: true });
            this.setState({ showUserError: "" });
          } else {
            this.setState({ showUserError: res.data.msg });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  submitAuthentication() {
    const body = {
      prefix: this.state.prefix,
      user_id: this.state.phone_number,
      authenticationNumber: this.state.authenticationNumber
    };
    axiosInstance
      .post(`submitAuthentication`, body)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          cookies.set("response", res.data);
          if (res.data.chat_available_status == "1") {
            //get vnc number and store
            let vnc_number_length = res.data.details.length;
            if (vnc_number_length > 0) {
              let vnc_number = res.data.details[0].vnc_chat_number;
              this.setState({ vnc_number: vnc_number });
            }

            //getting mobile number
            let customer_mobile_number = res.data.customer_mobile;
            let tech_mobile_number = res.data.tech_mobile;
            this.setState({ customer_mobile_number, tech_mobile_number });

            cookies.set("fixesRequestChatId", res.data.fix_request_id);
            cookies.set("userName", res.data.tech_name);
            this.setState({ showError: "" });
            this.setState({ showClientDetails: true });
            this.setState({ st_customer_user_id: res.data.customer_user_id });
            this.launchFunctionJoinRoom(res.data.customer_user_id);
          } else {
            this.setState({
              showError:
                "Ticket is not created, Please create ticket in mobile application to proceed"
            });
          }
        } else {
          this.setState({ errorMessage: res.data.msg });
          this.setState({ showError: "Invalid Authentication Number" });
        }
      })
      .catch((err) => console.log(err));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSuccess() {
    alert("as");
  }

  launchFunctionJoinRoom(id) {
    let user_var = "scanConnectionUserCustomerTSAS_" + id;
    let { socket } = this.state;
    // socket.emit("join room", user_var);

    //this.setState({showClientDetails: true});
    //this.setState({showLaunch: true});
  }

  launchConnectionFunction(active, download, install) {
    this.setState({ showClientDetails: false });
    if (download == 1) {
      this.setState({ showLaunch: true });

      //ttfconnectvnc: -controlservice -dispatch  connect.touchtofix.com::443\9566530513

      let element_launch = document.getElementById("launch_launch");
      if (element_launch != null) {
        let country_code = this.state.prefix;
        let mobile_number = this.state.phone_number;
        country_code = country_code.replace("+", "");

        //let url_launch = "ttfconnectvnc: -controlservice -dispatch  connect.touchtofix.com::443\""+mobile_number+" ";
        //let url_launch = "ttfconnectvnc: -controlservice -dispatch  connect.touchtofix.com::443"+"\\"+vnc_chat_number+"";

        //let customer_number = this.state.customer_mobile_number;
        //let tech_number = this.state.tech_mobile_number;
        //let connection_number_vnc = customer_number + 'TO' + tech_number;
        //let url_launch = "ttfconnectvnc: -controlservice -dispatch  connect.touchtofix.com::443\\" +connection_number_vnc ;

        let chat_vnc_number = this.state.vnc_number;
        let dummy_number = 1111111111;
        let connection_to_number = chat_vnc_number + "TO" + dummy_number;
        //let url_launch = "ttfconnectvnc: -controlservice -dispatch connect.touchtofix.com::443" + "\\" + connection_to_number;
        let url_launch =
          "ttfconnectvnc: -controlservice -dispatch connect.touchtofix.com::443" +
          "/" +
          connection_to_number;

        element_launch.href = url_launch;
      }
    } else {
      this.setState({ showDownload: true });
      //            let ele = document.getElementById("download_launch");
      //            if(ele != null) {
      //
      //                //let url = "https://api.touchtofix.com/demo/TouchtofixviewerInstaller.msi?" + this.state.prefix + '/' + this.state.phone_number;
      //
      //                let country_code = this.state.prefix;
      //                let mobile_number = this.state.phone_number;
      //                country_code = country_code.replace("+", "");
      //
      //                let folder_name = country_code + mobile_number;
      //                let url = "https://api.touchtofix.com/demo/sanyaa-api/VNC_SETUP/"+folder_name+ "/" + folder_name + ".msi";
      //                ele.href = url;
      //            }
    }

    this.deviceScannedSuccess();
  }

  updateDownload() {
    //        alert(1);
    const body = {
      customer_user_id: this.state.st_customer_user_id
    };
    axiosInstance
      .post(`updateDownloadAction`, body)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          this.setState({ showClientDetails: false });
          this.setState({ showDownload: false });
          this.setState({ showLaunch: true });

          let element_launch = document.getElementById("launch_launch");
          if (element_launch != null) {
            let country_code = this.state.prefix;
            let mobile_number = this.state.phone_number;
            country_code = country_code.replace("+", "");

            //let chat_vnc_number = this.state.vnc_number;
            //let url_launch = "ttfconnectvnc: -controlservice -dispatch  connect.touchtofix.com::443/" +chat_vnc_number ;

            //let customer_number = this.state.customer_mobile_number;
            //let tech_number = this.state.tech_mobile_number;
            //let connection_number_vnc = customer_number + 'TO' + tech_number;
            //let url_launch = "ttfconnectvnc: -controlservice -dispatch  connect.touchtofix.com::443\\" +connection_number_vnc ;
            //let url_launch = "ttfconnectvnc url ttfconnectvnc: -controlservice -dispatch connect.touchtofix.com::443\\" +connection_number_vnc ;

            let chat_vnc_number = this.state.vnc_number;
            let dummy_number = 1111111111;
            let connection_to_number = chat_vnc_number + "TO" + dummy_number;
            //let url_launch = "ttfconnectvnc: -controlservice -dispatch connect.touchtofix.com::443\\" +connection_to_number ;
            let url_launch =
              "ttfconnectvnc: -controlservice -dispatch connect.touchtofix.com::443/" +
              connection_to_number;

            element_launch.href = url_launch;
          }

          let country_code_a = this.state.prefix;
          let mobile_number_a = this.state.phone_number;
          country_code_a = country_code_a.replace("+", "");

          let folder_name = country_code_a + mobile_number_a;
          let url =
            "https://api.touchtofix.com/demo/sanyaa-api/VNC_SETUP/" +
            folder_name +
            "/" +
            folder_name +
            ".msi";
          window.location.href = url;
        } else {
        }
      })
      .catch((err) => console.log(err));
    return true;
  }

  deviceScannedSuccess() {
    console.log(2);
    const body = {
      customer_user_id: this.state.st_customer_user_id
    };
    axiosInstance
      .post(`getLatestCustomerChatDetails`, body)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let image_url = "";
          let file_extension = "";
          let to_connect = res.data.chat_session;
          let message = "Device scanned successfully";
          let type = "C";
          let userNameSocket = "VNC Server";
          let { socket } = this.state;
          socket.emit(
            "get_chat_details",
            to_connect,
            message,
            image_url,
            type,
            userNameSocket,
            file_extension
          );
          console.log("socket sent");
        } else {
          console.log("socket not sent");
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/" />;
    }

    if (this.state.dashboardRedirect) {
      return <Redirect to={{ pathname: "/Dashboard" }} />;
    }

    return (
      <div className="bg-color">
        <div className="wrapper">
          <header className="cd-main-header js-cd-main-header">
            {/* <button data-drawer-open="" className="reset cd-nav-trigger js-cd-nav-trigger btn-open" aria-label="Toggle menu"><span></span></button>*/}
            <img
              src="assets/images/logo-white.png"
              alt="Logo"
              className="menu-logo fix-logo"
            />
          </header>
          <main className="cd-main-content">
            <nav className="cd-side-nav js-cd-side-nav" id="mainNav">
              <div style={{ padding: "20px 20px" }}>
                <a
                  href="https://touchtofix.com/Privacypolicy"
                  target="_blank"
                  style={{ color: "white", display: "flex", gap: "10px" }}
                >
                  <div style={{ width: "10px" }}>
                    <i className="fa fa-lock" aria-hidden="true"></i>
                  </div>
                  Privacy Policy
                </a>
              </div>
              <div style={{ padding: "10px 20px" }}>
                <a
                  href="https://touchtofix.com/terms-conditions/"
                  target="_blank"
                  style={{ color: "white", display: "flex", gap: "10px" }}
                >
                  <div style={{ width: "10px" }}>
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  </div>
                  Terms & Condition
                </a>
              </div>
            </nav>
            <div className="cd-content-wrapper mystyle" id="main">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <form
                      style={{ padding: "5% 7%" }}
                      className="login100-form validate-form"
                    >
                      {this.state.showClientDetails ||
                      this.state.showDownload ||
                      this.state.showLaunch ? null : (
                        <div className="data-inputfields">
                          <div className="wrap-input100 validate-input m-b-10">
                            <span className="label-input100">
                              Mobile Number
                            </span>
                            <div className="selected-flag authlogin">
                              <select onChange={this.getCountryPrefix}>
                                {this.state.teams.map((team) => (
                                  <option
                                    key={team.country_id}
                                    data-length={team.mobile_length}
                                    value={team.prefix}
                                  >
                                    {" "}
                                    {team.prefix}{" "}
                                  </option>
                                ))}
                              </select>

                              <input
                                className="input100 mobile_input sp"
                                type="number"
                                name="phone_number"
                                autoComplete="off"
                                placeholder="Enter your mobile number"
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div
                            className="m-b-5"
                            style={{
                              fontSize: 12,
                              color: "#D90429",
                              fontWeight: "bold"
                            }}
                          >
                            {this.state.phoneError}
                          </div>
                          <div
                            className="m-b-5"
                            style={{
                              fontSize: 12,
                              color: "#D90429",
                              fontWeight: "bold"
                            }}
                          >
                            {this.state.showUserError}
                          </div>

                          {this.state.showOtpField ? (
                            <div>
                              <div
                                id="otp"
                                className="wrap-input100 validate-input m-b-23 show"
                              >
                                <span className="label-input100">
                                  Authentication Number
                                </span>
                                <input
                                  className="input100 mobile_input"
                                  style={{ fontSize: "20px" }}
                                  type="text"
                                  name="authenticationNumber"
                                  onChange={this.onChange}
                                  placeholder="Type your Authenticaion Number"
                                />
                                <span
                                  className="focus-input100"
                                  data-symbol="&#10148;"
                                ></span>
                              </div>
                              <div
                                className="m-b-5"
                                style={{
                                  fontSize: 12,
                                  color: "#D90429",
                                  fontWeight: "bold"
                                }}
                              >
                                {this.state.showError}
                              </div>
                            </div>
                          ) : null}

                          {this.state.showOtpField ? (
                            <div className="container-login100-form-btn">
                              <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <a
                                  onClick={this.submitAuthentication}
                                  className="custom-txt-none custom-a-line-none"
                                >
                                  <button
                                    id="submit"
                                    type="button"
                                    className="login100-form-btn"
                                  >
                                    Verify
                                  </button>
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="container-login100-form-btn">
                              <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <a
                                  onClick={this.verifyUserDetails}
                                  className="custom-txt-none custom-a-line-none"
                                >
                                  <button
                                    id="submit"
                                    type="button"
                                    className="login100-form-btn"
                                  >
                                    Verify
                                  </button>
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.showClientDetails ? (
                        <div className="content-area">
                          <h1>You are connected to expert:</h1>
                          <h2>{cookies.get("userName")}</h2>
                          <h1 style={{ marginTop: "20px" }}>
                            For further troubleshooting, you can choose to give
                            screensharing and remote access to the expert.
                          </h1>
                          <ul style={{ padding: "20px" }}>
                            <div className="item">
                              <div className="li">
                                Secure single instance connection
                              </div>
                            </div>
                            <div className="item">
                              <div className="li">
                                No need to install, tap to open
                              </div>
                            </div>
                            <div className="item">
                              <div className="li">
                                Easy and quick troubleshooting
                              </div>
                            </div>
                          </ul>
                          <h1 style={{ marginTop: "10px" }}>
                            By downloading FixMe below, you are hereby
                            responsible for information shared between you and
                            the expert and agreeing to terms and conditions of
                            FixMe and related softwares.
                          </h1>
                          <div style={{ display: "flex", gap: "40px" }}>
                            <div style={{ marginTop: "60px" }}>
                              <h3>Download for Windows</h3>
                              <img
                                src="assets/images/win.png"
                                alt=""
                                width="160px"
                                onClick={() => {
                                  window.location.href =
                                    "https://api.touchtofix.com/fixme_touchtofix_win.exe";
                                }}
                              />
                            </div>
                            <div style={{ marginTop: "60px" }}>
                              <h3>Download for MacOS</h3>
                              <img
                                src="assets/images/mac.png"
                                alt=""
                                width="160px"
                                onClick={() => {
                                  window.location.href =
                                    "https://api.touchtofix.com/fixme_touchtofix_mac.dmg";
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "20px"
                            }}
                          >
                            <h1>Powered by</h1>
                            <img
                              src="assets/images/anydesk.png"
                              alt=""
                              width="100px"
                              onClick={() => {
                                window.open(
                                  "https://anydesk.com/en/terms",
                                  "_blank"
                                );
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </form>
                  </div>
                  {this.state.showClientDetails ||
                  this.state.showDownload ||
                  this.state.showLaunch ? (
                    <div className="col-md-6">
                      <ChatComponent socket={this.state.socket} />
                    </div>
                  ) : (
                    <div className="col-md-6 nochat-history-div">
                      {/* <p>Please create ticket in mobile app to view chat here</p> */}

                      <img
                        src="assets/images/fixme 1.png"
                        alt=""
                        width="400"
                        style={{ marginTop: "40px" }}
                      />
                    </div>
                  )}
                </div>
                {!this.state.showClientDetails ? (
                  <div className="text-center fixme-footer">
                    <p>© Copyrights 2020 TouchToFix. All rights reserved.</p>
                  </div>
                ) : null}
              </div>
            </div>
          </main>
          <div></div>
        </div>
      </div>
    );
  }
}

export default AuthLoginComp;
