import React from "react";
import { Link } from "react-router-dom";
// import ChatDetailsComponent from './ChatDetailsComp'

const RenderMedia = (props) => {
  return (
    <div>
      <video width="320" height="240" controls>
        <source src={props.mediaFileUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default RenderMedia;
