import React, {Component} from 'react';
import CustomToolbar from '../component/Toolbar/CustomToolbar'
import SideDrawer from '../component/SideDrawer/SideDrawer'
import Backdrop from '../component/Backdrop/Backdrop'
// import BottomNavigation from '../component/BottomNavigation/BottomNavigation'
import ChatDetailsComp from '../component/Chat/ChatDetailsComp'

class Fixes extends Component {

    state = {
        sideDrawerOpen: false,
      }

      drawerToggleClickHandler = () => {
        this.setState(prevState => {
          return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
      }

      backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
      }
      

    render() {
        let backdrop

        if (this.state.sideDrawerOpen) {
          backdrop = <Backdrop click={this.backdropClickHandler} />
        }

        // const isMobile = window.innerWidth <= 500;
        return (
            <div style={{height: '100%'}}>

                <CustomToolbar drawerClickHandler={this.drawerToggleClickHandler} click='backChat'
                         propsName = 'chatDetails' 
                         headTitle = 'Touch to fix'
                         addLink = ''
                         backLink = '/Dashboard' />

                <SideDrawer show={this.state.sideDrawerOpen} />

                {/* BackDrop */}
                {backdrop}

                {/* *********** Main content starts here *********** */}

                <main>
                <div className="top__align__tool_nv"></div>
                    
                  <ChatDetailsComp />
                  
                  <div class="adjust-screens-bottom"></div>
                </main>

                {/* <BottomNavigation /> */}
            </div>
        )
    }

}

export default Fixes
