import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.css";
import ChatDetails from "./Pages/ChatDetails";
import AuthLogin from "./Pages/AuthLogin";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={AuthLogin} />
        <Route exact path="/ChatDetails" component={ChatDetails} />
        <Route exact path="/AuthLogin" component={AuthLogin} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
