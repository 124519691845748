import axios from "axios";

export const axiosInstance = axios.create({
//  baseURL: 'http://localhost/main-folder/projects/tsas-sanyaa/TsasCustomer/'
//   baseURL: 'http://35.213.150.135/demo/sanyaa-api/index.php/TsasCustomer/'
   baseURL: 'https://api.touchtofix.com/demo/sanyaa-api/index.php/TsasCustomer/'
});

// export const axiosConfig = axios.create({
//   headers: {
//     'Content-Type': 'application/json;charset=UTF-8',
//     "Access-Control-Allow-Origin": "*",
//   }
// });
