import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./ChatDetailsComp.css";
import { axiosInstance } from "../../Services/PostData";
import { SocketConnection } from "../../Services/SocketConnection";
import swal from "sweetalert2";
import io from "socket.io-client";
import Cookies from "universal-cookie";
import RenderMediaComponent from "./RenderMedia";

//camera
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import ImagePreview from "./ImagePreview";
//Video
import VideoRecorder from "react-video-recorder";
//zoom image
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const cookies = new Cookies();
//let socket = io.connect(SocketConnection)

class ChatDetailsComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseValue: [],
      description: "",
      image_file: "",
      chatMessageError: "",
      socket: props.socket
    };
    this.getRoomDetails = this.getRoomDetails.bind(this);
    this.submitInsertChat = this.submitInsertChat.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleTakePhotoAnimationDone =
      this.handleTakePhotoAnimationDone.bind(this);
    this.openMedia = this.openMedia.bind(this);
    this.handleImagePreview = this.handleImagePreview.bind(this);
    this.openCamera = this.openCamera.bind(this);
    this.closeCamera = this.closeCamera.bind(this);
    this.openVideo = this.openVideo.bind(this);
    this.closeVideo = this.closeVideo.bind(this);
    this.openMedia = this.openMedia.bind(this);
  }

  componentDidMount() {
    console.log(this.state.socket);
    const baseUrl = window.$baseUrl;
    this.setState({ baseUrl });

    this.getRoomDetails();
    // this.getChatAvailableStatus();
    if (cookies.get("sessionCusChatRoomId") !== undefined) {
      this.getChatRoomDetails(cookies.get("sessionCusChatRoomId"));
      this.setState({
        chat_room_detail_id: cookies.get("sessionCusChatRoomId")
      });
      this.setState({ socketUserName: cookies.get("socketUserName_customer") });
    } else {
      this.getRoomDetails();
    }

    this.state.socket.emit("join room", cookies.get("socket_subscribeId"));

    //***get socket chat details ***
    this.state.socket.on("get_chat_details", (name, data) => {
      let response = data;
      let responseValue_arr = this.state.responseValue;
      responseValue_arr.push(response[0]);

      let responseDetails = responseValue_arr.map((detail_values) => {
        if (detail_values.file_extension === undefined) {
          const parts = detail_values.file_name.split(".");
          const extension = parts[parts.length - 1];
          detail_values.file_extension = extension;
        }
        console.log({ detail_values });
        return detail_values;
      });
      this.setState({ responseValue: responseDetails });

      //***scroll to top ***
      this.scrollToBottom();
    });

    //***get socket send confirmation ***
    this.state.socket.on("sendChatConfirmation", (name, message, type) => {
      console.log(type);
      if (type == 1) {
        swal
          .fire({
            text: message,
            type: "success"
          })
          .then((result) => {
            window.location.reload(false);
          });
      } else if (type == 0) {
        swal
          .fire({
            text: message,
            type: "info"
          })
          .then((result) => {
            this.setState({ dashboardRedirect: true });
          });
      } else if (type == 2) {
        // swal.fire({
        //   text: message,
        //   type: 'info'
        // }).then ((result) => {  })
        swal
          .fire({
            text: message,
            showCancelButton: true,
            type: "info",
            confirmButtonColor: "#D90429",
            cancelButtonText: "No",
            confirmButtonText: "Yes",
            closeOnConfirm: false,
            closeOnCancel: false,
            allowOutsideClick: false
          })
          .then((result) => {
            if (result.value) {
              this.snoozeStatusUpdate(1);
            } else {
              this.snoozeStatusUpdate(0);
            }
          });
      }
    });

    //***close ticket ***
    this.state.socket.on("closeChatRoonConnection", (name, message) => {
      this.closeChatConnection(name, message);
    });
  }

  // getChatAvailableStatus() {
  //   const postvalues = { user_id: cookies.get('userId') };
  //   axiosInstance.post(`getChatAvailableStatus`, postvalues)
  //   .then(res => {
  //   if(res.data.status === 'success' && res.data.statusCode ==='200' )
  //   {
  //       let chat_response = res.data.chat_details;
  //       if(chat_response.length ==0) {
  //           cookies.remove('fixesRequestChatId')
  //       }
  //   }
  //   })
  //   .catch(err => console.log(err));
  // }

  snoozeStatusUpdate(req_status) {
    let fixesRequestChatId = cookies.get("fixesRequestChatId");

    const body = { fixes_req_id: fixesRequestChatId, status: req_status };
    axiosInstance
      .post(`snoozeStatusUpdate`, body)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          if (req_status == 0 || req_status == "0") {
            cookies.remove("fixesRequestChatId");

            //***send socket snooze confirmation ***
            let to_connect = "tech-user" + res.data.tech_user_id;
            let message = "Customer Rejected your snooze";
            let type = "snooze_reject";
            this.state.socket.emit(
              "sendChatConfirmation",
              to_connect,
              message,
              type
            );

            swal
              .fire({
                text: "Snooze request reject",
                type: "success"
              })
              .then((result) => {
                this.setState({ dashboardRedirect: true });
              });
          } else if (req_status == 1 || req_status == "1") {
            //***send socket snooze confirmation ***
            let to_connect = "tech-user" + res.data.tech_user_id;
            let message = "Customer Accepted your snooze";
            let type = "snooze_accept";
            this.state.socket.emit(
              "sendChatConfirmation",
              to_connect,
              message,
              type
            );

            swal
              .fire({
                text: "Snooze request Accepted",
                type: "success"
              })
              .then((result) => {});
          }
        } else {
          if (req_status == 0 || req_status == "0") {
            cookies.remove("fixesRequestChatId");
          }
        }
      })
      .catch((err) => console.log(err));
  }

  getRoomDetails() {
    //disable text area and file attach button
    document.getElementById("description").disabled = true;
    document.getElementById("image_url").disabled = true;
    this.setState({ fixersResponse: true });

    let fixesRequestChatId = cookies.get("fixesRequestChatId");
    if (fixesRequestChatId == undefined) {
      this.setState({ dashboardRedirect: true });
    } else {
      const body = { fixId: fixesRequestChatId };
      axiosInstance
        .post(`getFixesRequestStatus`, body)
        .then((res) => {
          if (res.data.status === "success" && res.data.statusCode === "200") {
            let response = res.data.fixes_details;
            if (response.length != 0) {
              if (
                response[0].update_status == 1 &&
                response[0].accept_status == 1
              ) {
                var chat_details = this.getChatDetails(
                  response[0].fixes_request_id
                );

                //disable text area and file attach button
                document.getElementById("description").disabled = false;
                document.getElementById("image_url").disabled = false;
                this.setState({ fixersResponse: false });
              }
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }

  getChatRoomDetails(id) {
    const body = { chat_room_id: id };
    axiosInstance
      .post(`getChatRoomDetails`, body)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let response = res.data.chat_details;
          // if(res.data.title_status == 1 ) {
          //   this.getSubjectfunction(id);
          // }
          if (response.length != 0) {
            let notTicketFound = "hide__container";
            this.setState({ notTicketFound });
          }
          let responseDetails = response.map((detail_values) => {
            return detail_values;
          });
          this.setState({ responseValue: responseDetails });

          //***scroll to top ***
          this.scrollToBottom();
        }
      })
      .catch((err) => console.log(err));
  }

  getChatDetails(id) {
    const body = { fixes_req_id: id };
    axiosInstance
      .post(`getChatDetails`, body)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let room_response = res.data.chat_details;
          if (room_response.length != 0) {
            this.getChatRoomDetails(room_response[0].chat_room_id);

            let chat_room_detail_id = room_response[0].chat_room_id;
            let chat_room_tech_id = room_response[0].tech_user_id;
            let chat_room_cus_id = room_response[0].cus_user_id;
            let chat_room_session = room_response[0].chat_session;
            let socketUserName = room_response[0].user_name;

            cookies.set("sessionCusChatRoomId", chat_room_detail_id);
            cookies.set("socketUserName_customer", socketUserName);
            this.state.socket.emit("join room", chat_room_session);
            this.setState({
              chat_room_detail_id,
              chat_room_tech_id,
              chat_room_cus_id,
              chat_room_session,
              socketUserName
            });
          }
        }
      })
      .catch((err) => console.log(err));
  }

  getSubjectfunction(chat_id) {
    swal
      .fire({
        text: "Enter your subject here",
        input: "text",
        showCancelButton: false,
        confirmButtonColor: "#D90429",
        confirmButtonText: "submit",
        closeOnConfirm: false,
        allowOutsideClick: false
      })
      .then((result) => {
        if (result.value) {
          this.submitSubject(chat_id, result.value);
        }
      });
  }

  submitSubject(chat_id, subject) {
    const body = {
      chat_room_id: chat_id,
      subject: subject
    };

    axiosInstance
      .post(`updateFixesTitleDetails`, body)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let to_connect = this.state.chat_room_session;
          let message = subject;
          let image_url = "";
          let type = "C";
          let userNameSocket = this.state.socketUserName;

          this.state.socket.emit(
            "get_chat_details",
            to_connect,
            message,
            image_url,
            type,
            userNameSocket
          );

          swal
            .fire({
              text: "subject added successfully",
              type: "success"
            })
            .then((result) => {});
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  handleImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    let attach_file_name = e.target.files[0].name;

    this.setState({
      image_preview: image_as_base64,
      image_file: image_as_files,
      attach_file_name: attach_file_name
    });
  };

  closeChatConnection(name, message) {
    swal
      .fire({
        text: message,
        type: "success"
      })
      .then((result) => {
        this.cancelFixes();
      });
  }

  cancelFixes() {
    let queryValue = cookies.get("sessionCusChatRoomId");
    const body = { chat_room_id: queryValue };
    axiosInstance
      .post(`closeFixes`, body)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          cookies.remove("sessionCusChatRoomId");
          this.setState({ dashboardRedirect: true });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  submitInsertChat() {
    var query = window.location.search.substring(1);
    var pair = query.split("=");
    let queryValue = pair[1];

    let formData = new FormData();
    formData.append("imageFile", this.state.image_file);
    formData.append("chat_room_id", this.state.chat_room_detail_id);
    formData.append("description", this.state.description);

    if (this.state.description != "" || this.state.image_file != "") {
      axiosInstance
        .post(`insertCustomerChatMessage`, formData)
        .then((res) => {
          let socket_message = this.state.description;

          //reset chat form
          this.state.description = "";
          this.state.attach_file_name = "";
          this.state.image_file = "";
          document.getElementById("image_url").value = "";
          this.setState({ chatMessageError: "" });
          document.getElementById("sendBtnCht").disabled = true;
          document.getElementById("typing-loader").classList.add("show");

          if (res.data.status === "success" && res.data.statusCode === "200") {
            let image_url = "";
            let file_extension = "";
            if (res.data.chat_details.length != 0) {
              image_url = res.data.chat_details[0].file_name;
              file_extension = res.data.chat_details[0].file_extension;
            }

            // let to_connect   = 'tech-user' + this.state.chat_room_tech_id;
            let to_connect = this.state.chat_room_session;
            // let message = this.state.description;
            let message = socket_message;
            let type = "C";
            let userNameSocket = this.state.socketUserName;

            this.state.socket.emit(
              "get_chat_details",
              to_connect,
              message,
              image_url,
              type,
              userNameSocket,
              file_extension
            );

            document.getElementById("typing-loader").classList.remove("show");
            document.getElementById("sendBtnCht").disabled = false;
            // this.getRoomDetails();
          }
        })
        .catch((err) => console.log("Login: ", err));
    } else {
      let chatMessageError = "please type something or choose file to send";
      this.setState({ chatMessageError });
      document.getElementById("sendBtnCht").disabled = false;
    }
  }

  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  scrollToBottom() {
    const messagess = document.getElementById("chatMessagess");
    messagess.scrollTop = messagess.scrollHeight;
  }

  //********** camera

  handleTakePhotoAnimationDone(dataUri) {
    this.setState({ dataUri });

    this.closeCamera();
    this.setState({ previewImage: true });

    // let unique_name = Date.now()+'.png';
    // var file_ = new File([this.state.dataUri], unique_name, { type: 'image/png', length: 1 } );

    // let image_as_base64 = URL.createObjectURL(file_)
    // let image_as_files = file_;
    // let attach_file_name = file_.name;

    // // dataUri = dataUri.split(',')[1];
    // let formData = new FormData();
    // // formData.append('imageFile', image_as_files);
    // formData.append('imageFile', dataUri);
    // formData.append('chat_room_id', this.state.chat_room_detail_id );
    // formData.append('description', this.state.description);

    // let config = {
    //   header : {
    //     'Content-Type' : 'multipart/form-data'
    //   }
    // }
    // axiosInstance.post(`insertCustomerChatMessage`, formData, config)
    // .then(res => {

    //   if(res.data.status === 'success' && res.data.statusCode ==='200' )
    //   {
    //       let image_url = "";
    //       let file_extension = '';
    //       if(res.data.chat_details.length != 0) {
    //         image_url = res.data.chat_details[0].file_name;
    //         file_extension = res.data.chat_details[0].file_extension;
    //       }

    //       // let to_connect   = 'tech-user' + this.state.chat_room_tech_id;
    //       let to_connect   = this.state.chat_room_session;
    //       // let message = this.state.description;
    //       let message = "";
    //       let type = 'C';
    //       let userNameSocket = this.state.socketUserName

    //       socket.emit('get_chat_details', to_connect, message, image_url, type, userNameSocket, file_extension)

    //       document.getElementById("typing-loader").classList.remove("show");
    //       document.getElementById("sendBtnCht").disabled = false;
    //       // this.getRoomDetails();
    //       this.closeCamera();

    //   }

    // })
    // .catch(err => console.log(err));
  }

  cancelImageTaken() {
    this.setState({ previewImage: false });
  }

  submitImageTaken() {
    //show spinner
    this.setState({ showSpinner: true });

    let unique_name = Date.now() + ".png";
    var file_ = new File([this.state.dataUri], unique_name, {
      type: "image/png",
      length: 1
    });

    let image_as_base64 = URL.createObjectURL(file_);
    let image_as_files = file_;
    let attach_file_name = file_.name;

    // dataUri = dataUri.split(',')[1];
    let formData = new FormData();
    // formData.append('imageFile', image_as_files);
    formData.append("imageFile", this.state.dataUri);
    formData.append("chat_room_id", this.state.chat_room_detail_id);
    formData.append("description", this.state.description);

    let config = {
      header: {
        "Content-Type": "multipart/form-data"
      }
    };
    axiosInstance
      .post(`insertCustomerChatMessage`, formData, config)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let image_url = "";
          let file_extension = "";
          if (res.data.chat_details.length != 0) {
            image_url = res.data.chat_details[0].file_name;
            file_extension = res.data.chat_details[0].file_extension;
          }

          // let to_connect   = 'tech-user' + this.state.chat_room_tech_id;
          let to_connect = this.state.chat_room_session;
          // let message = this.state.description;
          let message = "";
          let type = "C";
          let userNameSocket = this.state.socketUserName;

          this.state.socket.emit(
            "get_chat_details",
            to_connect,
            message,
            image_url,
            type,
            userNameSocket,
            file_extension
          );

          document.getElementById("typing-loader").classList.remove("show");
          document.getElementById("sendBtnCht").disabled = false;
          // this.getRoomDetails();

          //hide spinner
          this.setState({ showSpinner: false });

          this.closeCamera();
          this.cancelImageTaken();
        }
      })
      .catch((err) => console.log(err));
  }

  sendVideoData(videoData) {
    //show spinner
    this.setState({ showSpinner: true });

    let rndmNumber = Math.floor(Math.random() * 99999);
    let video_unique_name = Date.now();
    let video_file_name = rndmNumber + video_unique_name + ".mp4";

    let video_file_ = videoData;
    video_file_.name = video_file_name;
    let image_as_base64 = URL.createObjectURL(video_file_);
    let image_as_files = video_file_;

    let formData = new FormData();
    formData.append("imageFile", image_as_files);
    formData.append("chat_room_id", this.state.chat_room_detail_id);
    formData.append("description", this.state.description);

    let config = {
      header: {
        "Content-Type": "multipart/form-data"
      }
    };
    axiosInstance
      .post(`insertCustomerChatMessage`, formData, config)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let image_url = "";
          let file_extension = "";
          if (res.data.chat_details.length != 0) {
            image_url = res.data.chat_details[0].file_name;
            file_extension = res.data.chat_details[0].file_extension;
          }

          let to_connect = this.state.chat_room_session;
          let message = "";
          let type = "C";
          let userNameSocket = this.state.socketUserName;

          this.state.socket.emit(
            "get_chat_details",
            to_connect,
            message,
            image_url,
            type,
            userNameSocket,
            file_extension
          );

          document.getElementById("typing-loader").classList.remove("show");
          document.getElementById("sendBtnCht").disabled = false;
          // this.getRoomDetails();
          this.closeVideo();

          //hide spinner
          this.setState({ showSpinner: false });
        }
      })
      .catch((err) => console.log(err));
  }

  openCamera() {
    this.setState({ cameraOpen: true });
  }

  closeCamera() {
    this.setState({ cameraOpen: false });
  }

  openVideo() {
    this.setState({ videoOpen: true });
  }

  closeVideo() {
    this.setState({ videoOpen: false });
  }

  openMedia(id) {
    this.setState({ showMediaSection: id });
    return RenderMediaComponent;
  }

  render() {
    if (this.state.dashboardRedirect) {
      return <Redirect to={{ pathname: "/Dashboard" }} />;
    }
    return (
      <div className="container-chat clearfix no-pad">
        {/* {this.state.cameraOpen ? 
                      <div>
                      <Camera onTakePhotoAnimationDone = {this.handleTakePhotoAnimationDone}
                              idealFacingMode = {FACING_MODES.ENVIRONMENT}
                              isImageMirror = {false} 
                              isFullscreen = {true}
                               />
                              <p onClick={this.closeCamera} style={{fontSize: '13px', textAlign: 'center'}} >
                              <i className="fa fa-times-circle" aria-hidden="true"></i> Close Camera
                              </p>
                      </div>
                      :
                      null
                    } */}

        <div className="chat">
          <div className="chat-history" id="chatMessagess">
            {this.state.fixersResponse ? (
              <div style={{ marginTop: "10%", textAlign: "center" }}>
                Waiting for fixer response
              </div>
            ) : null}

            {this.state.fixerConnectedResponse ? (
              <div style={{ marginTop: "10%", textAlign: "center" }}>
                Waiting for fixer response
              </div>
            ) : null}

            <ul>
              {this.state.responseValue.map((values) => (
                <li className="clearfix" key={values.chat_history_id}>
                  {values.type === "C" ? (
                    <span>
                      <div className="message-data align-right">
                        {/* <span className="message-data-time">10:10 AM, Today</span> &nbsp; &nbsp; */}
                        <span className="message-data-name">Me</span>{" "}
                        <i className="fa fa-circle me"></i>
                      </div>
                      {/* <div className="message other-message float-right"> */}
                      {values.file_extension == "jpg" ||
                      values.file_extension == "png" ||
                      values.file_extension == "jpeg" ||
                      values.file_extension == "JPG" ||
                      values.file_extension == "PNG" ||
                      values.file_extension == "JPEG" ? (
                        <div className="message other-message float-right cht-immg">
                          <Zoom>
                            <img
                              src={
                                this.state.baseUrl +
                                "uploads/chatAttach/" +
                                values.file_name
                              }
                              alt=""
                              width="150px"
                              style={{ float: "right", borderRadius: "10px" }}
                            />
                          </Zoom>
                        </div>
                      ) : values.file_extension == "mp4" ||
                        values.file_extension == "MP4" ||
                        values.file_extension == "3gp" ||
                        values.file_extension == "MOV" ||
                        values.file_extension == "mov" ||
                        values.file_extension == "3GP" ? (
                        <div>
                          <div className="message my-message other-message float-right">
                            <a
                              href="javascript:void(0);"
                              className="text-white"
                              style={{ textDecoration: "none" }}
                              onClick={() =>
                                this.openMedia(values.chat_history_id)
                              }
                            >
                              {/* {values.file_name} */}
                              <span className="material-icons">
                                videocam
                              </span>{" "}
                              Click to play video
                            </a>
                          </div>

                          {this.state.showMediaSection ==
                          values.chat_history_id ? (
                            <div className="message my-message cht-immg float-right">
                              <RenderMediaComponent
                                mediaFileUrl={
                                  this.state.baseUrl +
                                  "uploads/chatAttach/" +
                                  values.file_name
                                }
                                mediaFileName={values.file_name}
                                onClick={true}
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : values.file_name !== "" ? (
                        <div
                          className="float-right"
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            padding: "10px",
                            background: "white",
                            borderRadius: "12px",
                            width: "50%"
                          }}
                        >
                          Please read this message on the apps
                        </div>
                      ) : (
                        <div className="message other-message float-right">
                          {values.description}
                        </div>
                      )}

                      {/* </div>  */}
                    </span>
                  ) : (
                    <span>
                      <div className="message-data">
                        <span className="message-data-name">
                          <i className="fa fa-circle online"></i>{" "}
                          {values.userName}
                        </span>
                      </div>
                      {/* <div className="message my-message"> */}

                      {values.file_name != "" ? (
                        values.file_extension == "mp4" ||
                        values.file_extension == "MP4" ||
                        values.file_extension == "3gp" ||
                        values.file_extension == "MOV" ||
                        values.file_extension == "mov" ||
                        values.file_extension == "3GP" ? (
                          <div>
                            <div className="message my-message float-left">
                              <a
                                href="javascript:void(0);"
                                style={{
                                  textDecoration: "none",
                                  color: "black"
                                }}
                                onClick={() =>
                                  this.openMedia(values.chat_history_id)
                                }
                              >
                                {/* {values.file_name} */}
                                <span className="material-icons">
                                  videocam
                                </span>{" "}
                                Click to play video
                              </a>
                            </div>

                            {this.state.showMediaSection ==
                            values.chat_history_id ? (
                              <div className="message my-message cht-immg">
                                <RenderMediaComponent
                                  mediaFileUrl={
                                    this.state.baseUrl +
                                    "uploads/chatAttach/" +
                                    values.file_name
                                  }
                                  mediaFileName={values.file_name}
                                  onClick={true}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : values.file_extension == "jpg" ||
                          values.file_extension == "png" ||
                          values.file_extension == "jpeg" ||
                          values.file_extension == "JPG" ||
                          values.file_extension == "PNG" ||
                          values.file_extension == "JPEG" ? (
                          <div className="message my-message cht-immg">
                            <Zoom>
                              <img
                                src={
                                  this.state.baseUrl +
                                  "uploads/chatAttach/" +
                                  values.file_name
                                }
                                alt=""
                                width="150px"
                                style={{ borderRadius: "10px" }}
                              />
                            </Zoom>
                            {values.description}
                          </div>
                        ) : (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              padding: "10px",
                              background: "white",
                              borderRadius: "12px",
                              width: "50%"
                            }}
                          >
                            Please read this message on the app
                          </div>
                        )
                      ) : (
                        <div className="message my-message">
                          {values.description}
                        </div>
                      )}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>

          {/* 5f3fb6b384cf2202008210139pm */}

          {/* <video width="320" height="240" controls>
                    <source src={this.state.baseUrl+ 'uploads/chatAttach/5f3fb6b384cf2202008210139pm.mp4'} type="video/mp4" />
                    <source src={this.state.baseUrl+ 'uploads/chatAttach/5f3fb6b384cf2202008210139pm.mp4'} type="video/ogg" />
                  </video> */}

          <div className="chat-message clearfix">
            <textarea
              name="description"
              id="description"
              placeholder="Type your message"
              rows="3"
              value={this.state.description}
              onChange={this.onChangeHandler}
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.shiftKey) {
                } else if (e.key === "Enter") {
                  // Detects Enter without Shift
                  this.submitInsertChat();
                  e.preventDefault();
                }
              }}
            ></textarea>
            {/* <input className="fa fa-file-image-o" type="file" id="image_url" name='image_url' onChange={this.handleImagePreview} /> */}
            {/* <i className="fa fa-file-image-o" type="file" name='image_url' onChange={this.handleImagePreview}></i> */}
            {/* camera */}
            <label style={{ padding: "5px" }}>
              <i
                className="fa fa-camera"
                style={{ fontSize: "20px" }}
                onClick={() => this.openCamera()}
              ></i>
            </label>{" "}
            &nbsp;
            {/* Video */}
            <label>
              <i
                className="fa fa-video-camera"
                style={{ fontSize: "20px" }}
                onClick={() => this.openVideo()}
              ></i>
            </label>{" "}
            &nbsp;
            <label for="image_url">
              {/* <i className="fa fa-file-image-o"></i> */}
              <span className="material-icons" style={{ fontSize: "26px" }}>
                attach_file
              </span>
            </label>{" "}
            &nbsp;
            <input
              type="file"
              id="image_url"
              name="image_url"
              onChange={this.handleImagePreview}
              style={{ display: "none" }}
            />
            {/* attach file name */}
            <span style={{ fontSize: "12px" }}>
              {this.state.attach_file_name}
            </span>
            <span style={{ color: "#D90429", fontSize: "12px" }}>
              {this.state.chatMessageError}
            </span>
            <div className="typing-loader" id="typing-loader"></div>
            <button
              className="send-cht-btn"
              id="sendBtnCht"
              onClick={() => this.submitInsertChat()}
            >
              <span className="material-icons chat_mat_icn">send</span>
            </button>
            {/* **************** Camera start here *************** */}
            {this.state.cameraOpen ? (
              <div>
                <Camera
                  onTakePhotoAnimationDone={this.handleTakePhotoAnimationDone}
                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                  isImageMirror={false}
                  isFullscreen={true}
                />
                <p
                  onClick={this.closeCamera}
                  style={{
                    fontSize: "13px",
                    textAlign: "center",
                    color: "#d90429",
                    fontWeight: "600"
                  }}
                >
                  <i className="fa fa-times-circle" aria-hidden="true"></i>{" "}
                  Close Camera
                </p>
              </div>
            ) : null}
            {this.state.previewImage ? (
              <div>
                <ImagePreview
                  dataUri={this.state.dataUri}
                  isFullscreen={true}
                />
                <div className="image-preview-confirmation-box">
                  <p
                    className="imf-cnfrm-icons cancel"
                    onClick={() => this.cancelImageTaken()}
                  >
                    Cancel
                  </p>
                  <p
                    className="imf-cnfrm-icons okay"
                    onClick={() => this.submitImageTaken()}
                  >
                    okay
                  </p>
                </div>
              </div>
            ) : null}
            {this.state.videoOpen ? (
              <VideoRecorder
                onRecordingComplete={(videoBlob) => {
                  this.sendVideoData(videoBlob);
                  // Do something with the video...
                  // console.log(videoBlob)
                  // console.log(videoBlob.type)
                }}
              />
            ) : null}
          </div>
        </div>

        {this.state.showSpinner ? (
          <div className="lds-dual-ring custom-screen-middle"></div>
        ) : null}
      </div>

      // <div>
      //   {this.state.responseValue.map(values => (
      //     <p>{(values.type === 'C') ? 'C' : 'T' }</p>
      //   ))}
      // </div>
    );
  }
}

export default ChatDetailsComp;
